<template>
  <li
    class="nav-item has-sub"
    :class="{
      open: isOpen,
      disabled: item.disabled,
      'sidebar-group-active': isActive,
    }"
  >
  <!-- ////rtl link///// -->
    <b-link
      v-if="$i18n.locale == 'fa'"
      class="d-flex align-items-center"
      @click="() => updateGroupOpen(!isOpen)"
    >
      <feather-icon :icon="item.icon || 'CircleIcon'" />
      <span class="menu-title text-truncate">{{ $t(item.title) }}</span>
      <b-badge
        v-if="item.tag"
        pill
        :variant="item.tagVariant || 'primary'"
        class="mr-1 ml-auto"
      >
        {{ item.tag }}
      </b-badge>
    </b-link>
     <!-- ltr link -->
     <b-link
      v-else
      :class="{'ltr_nav_group_link_open':isOpen,'ltr_nav_group_link_close':!isOpen }"
      class="d-flex align-items-center"
      @click="() => updateGroupOpen(!isOpen)"
    >
      <feather-icon :icon="item.icon || 'CircleIcon'" />
      <span class="menu-title text-truncate font-weight-bold">{{
        $t(item.title)
      }}</span>
      <b-badge
        v-if="item.tag"
        pill
        :variant="item.tagVariant || 'primary'"
        class="mr-1 ml-auto"
      >
        {{ $t(`Menu.${item.tag}`) }}
      </b-badge>
    </b-link>
    <b-collapse v-model="isOpen" class="menu-content" tag="ul">
      <component
        :is="resolveNavItemComponent(child)"
        v-for="child in item.children"
        :key="child.header || child.title"
        ref="groupChild"
        :item="child"
      />
    </b-collapse>
  </li>
</template>

<script>
import { BLink, BBadge, BCollapse } from "bootstrap-vue";
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from "@core/layouts/utils";
import VerticalNavMenuHeader from "../vertical-nav-menu-header";
import VerticalNavMenuLink from "../vertical-nav-menu-link/VerticalNavMenuLink.vue";

// import { ref } from '@vue/composition-api'

// Composition Function
import useVerticalNavMenuGroup from "./useVerticalNavMenuGroup";
import mixinVerticalNavMenuGroup from "./mixinVerticalNavMenuGroup";

export default {
  name: "VerticalNavMenuGroup",
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    BLink,
    BBadge,
    BCollapse,
  },
  mixins: [mixinVerticalNavMenuGroup],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { isOpen, isActive, updateGroupOpen, updateIsActive } =
      useVerticalNavMenuGroup(props.item);

    return {
      resolveNavItemComponent,
      isOpen,
      isActive,
      updateGroupOpen,
      updateIsActive,
    };
  },
};
</script>

<style lang="scss">
.has-sub {
  a.ltr_nav_group_link_close {
    &::after {
      transform: rotate(180deg) !important;
    }
  }
  a.ltr_nav_group_link_open {
    &::after {
      transform: rotate(270deg) !important;
    }
  }
}
</style>
